import React from 'react';
import PropTypes from 'prop-types';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import  ClientName from "./ClientName";
import  ClientImg from "./ClientImg";
import  ClientVideo from "./ClientVideo";
import  "./assets/_index.scss";



const OurClients = (props) => {
    return (
        <section className={`section section-our-clients${props.sectionClass}`}>
            <Row className="section-our-clients__row no-gutters">
                {props.ourClients.map((client, index) => (
                    <Col key={client.node.clientVideo.id} xs={6} md={4} xl={3} className="section-our-clients__item mb-73 mb-xl-90">
                        <ClientImg
                            imgClass="mb-36 mb-md-60"
                            posterImage={client.node.clientVideo.posterImage}
                        />
                        <ClientName
                            name={client.node.clientVideo.videoTitle}
                        />
                        <ClientVideo
                            showVideo={client.node.clientVideo.showVideo}
                            videoId={client.node.clientVideo.videoUrl}
                            videoChannel={client.node.clientVideo.videoChannel}
                            videoLabel={props.videoLabel}
                            hideCircleIcon={false}
                        />
                    </Col>
                ))}
            </Row>
        </section>
    )
}

// Check all the prop types
OurClients.propTypes = {
    sectionClass: PropTypes.string,
    ourClients: PropTypes.array
};

// Specifies the default values for props:
OurClients.defaultProps = {
    sectionClass: ' mb-12 mb-md-2 mb-xl-50',
    ourClients: [
        {
            id: 1,
            img: '',
            name: 'Hello Again',
            videoUrl: '#',
            videoLabel: 'Watch Review'
        },
        {
            id: 2,
            img: '',
            name: 'Hello Again',
            videoUrl: '#',
            videoLabel: 'Watch Review'
        },
        {
            id: 3,
            img: '',
            name: 'Hello Again',
            videoUrl: '#',
            videoLabel: 'Watch Review'
        },
        {
            id: 4,
            img: '',
            name: 'Hello Again',
            videoUrl: '#',
            videoLabel: 'Watch Review'
        },
        {
            id: 5,
            img: '',
            name: 'Hello Again',
            videoUrl: '#',
            videoLabel: 'Watch Review'
        },
        {
            id: 6,
            img: '',
            name: 'Hello Again',
            videoUrl: '#',
            videoLabel: 'Watch Review'
        }
    ]
};

export default OurClients;