import React from 'react';
import Img from "gatsby-image"

const Image = (props) => {
    return (
        <Img fluid={props.posterImage.childImageSharp.fluid} />
    )
}

const ClientImg = (props) => {
    return (
        <div className={props.imgClass}>
            {props.posterImage &&
                <Image {...props} />
            }
        </div>
    );
}

export default ClientImg;

