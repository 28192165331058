import React from 'react';
import VideoModalLink from "@components/modal/video-modal-link";

const ClientVideo = (props) => {
    return (
        <>
            {props.showVideo &&
                <div className="client__video__link">
                    <VideoModalLink
                        showVideo={props.showVideo}
                        videoId={props.videoId}
                        videoLabel={props.videoLabel}
                        videoChannel={props.videoChannel}
                        hideCircleIcon={props.hideCircleIcon}
                    />
                </div>
            }
        </>
    )
}

export default ClientVideo;